#text_Center{
     position: relative;
    /* right: 1120px; */
    bottom: 45px;
    /* display: flex; */
    /* flex-direction: row; */
    flex: 1;
    /* transform: translateX(500px);
    transform: translateY(-45px); 
    /* left: 800px; */
    left: 200px;
    inline-size: 150px;
    /* bottom: 690px; */
    z-index: 1;
    /* transform: translateY(-45px); 
    transform: translateX(250px); */
}
#userName{
    height: 35px;
}

#button{
    position: relative;
    bottom: 25px;
    right: 10px;
}
@media (max-width:1050px){
   #text_Center{
    position: fixed;
    display: flex;
    flex-direction: row;
    bottom: 580px;
    /* left: 400px; */
    left: 250px;
   }
   #button{
    position: relative;
    display: flex;
    bottom: 50px;
    left: 350px;
   }
}