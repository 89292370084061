.sub{
    cursor: pointer;
    color: blue;
}
#drop-down{
    width: 160px;
    
}



.submit{
    margin-left: 670px;
    position: fixed;
    bottom: 681px;
}
@media (max-width:991px){
    .submit{
        position: fixed;
        top: 210px;
       
    }
}


.dataTables_filter {
    display: flex;
    gap: 20px;
}


.btn-payment {
font-size: 18px;
padding: 6px 12px;
color: #fff; 
background: #2D2D2D;
border-radius: 6px;

}

