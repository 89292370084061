.img-con {
   display: flex;
   gap:10px
}

.img-con img {
    width: 50px;
    height: 50px;
    border-radius:6px;
}

.select-date {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}