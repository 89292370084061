.backdrop {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.379);
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100;
}

.exercise-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1212px;
  /* height: 636px; */
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 25px;

  display: flex;
  flex-direction: column;
  align-items: left;
}
.repeat-col-container {
  display: flex;
  align-items: center;
}
.exercise-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.repeat-label {
  display: inline;
  padding-right: 10px;
}
.exercise-col {
  flex: 1;
}

.exercise-col:nth-child(-n + 3) {
  flex: 4;
}
.exercise-col label {
  margin-bottom: 8px;
  font-weight: 600;
  display: block;
}

.exercise-col input,
.exercise-col select {
  width: 100%;
  border: 1px solid #d0d0d0;
  padding: 8px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.exercise-col button {
  /* width: 50%; */
  border: 1px solid #d0d0d0;
  /* padding: 8px 10px; */
  border-radius: 5px;
}

.exercise-col button:last-child {
  padding: 8px 20px;
}
.fill {
  width: 15%;
  font-size: 16px;
  padding: 8px 30px;
  color: #fff;
  border: none;
  background: #2d2d2d;
  border-radius: 6px;
  font-weight: 600;
}

.outline {
  width: 15%;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #2d2d2d;
  padding: 8px 30px;
  color: #2d2d2d;
  border-radius: 6px;
  font-weight: 600;
}

.repeat-col {
  margin-top: 0.9rem !important;
}

.exercise-flex {
  align-items: flex-end !important;
}

.final-btn {
  /* margin-top: 20px !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin: 0 auto; */
  margin: 20px auto 0 !important;
  gap: 20px;
}

.exercise-cols label {
  margin-bottom: 8px;
  font-weight: 600;
  display: block;
}

.exercise-cols input,
.exercise-cols select {
  width: 100%;
  border: 1px solid #d0d0d0;
  padding: 8px 10px;
  border-radius: 5px;
}

.rest-row {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  margin-top: 0;
}

.exercise-cols button {
  /* width: 50%; */
  border: 1px solid #d0d0d0;
  /* padding: 8px 10px; */
  border-radius: 5px;
  padding: 8px 20px;
}

.rest-checkbox-con {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
