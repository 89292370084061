.browseInput{
    border: none;

}
.browseInput::placeholder {
    color: #DF2E38; 
    font-weight: bold;
  }

.box-history{
    margin-left: 47px;
}
h6{
    margin-left: 50px;

}