.data-table {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* .data-container {
    margin-top: 10px;
    width: 95%;
    height: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
 */

.payment-table {
  margin-top: 10px;
  width: 95%;
  height: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.header {
  background-color: transparent;
  border: none;
}

.payment-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
