.divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}


.repeating-history-modal .modal-dialog {
  max-width: 500px;
  /* Adjust the value as per your requirements */
}

.repeating-participant-modal .modal-dialog {
  max-width: 600px;
  /* Adjust the value as per your requirements */
}

.list-control .white-icon {
  color: #d9d9d9;
}