.todayTask {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 50px;
  padding: 20px;
}

.task-table {
  width: 100%;
  border-collapse: collapse;
  padding: 20px;
  font-size: 18px;
  border: 2px solid #d0d0d0;
}

.task-table_header th,
.task-table_body td {
  padding: 20px;
  border-left: 2px solid #d0d0d0;
  border-right: 2px solid #d0d0d0;
}

.task-table_header th {
  border-bottom: 2px solid #d0d0d0;
}
