.history-container {
  width: 100%;
}

.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.accordion__header {
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  padding: 10px;
  padding-right: 20px;
}

.accordion-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.accordion-title-container h5 {
  margin: 0;
  padding: 0;
}

.expand-icon {
  transition: transform 0.3s ease;
  user-select: none;
  display: inline-block;
}

.expand-icon.open {
  transform: rotate(180deg);
}
