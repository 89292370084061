.backdrop {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.379);
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100;
}

.task-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 1.3rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.item3 {
  grid-column: 1 / 3;
  grid-row: 2;
}

.item4 {
  grid-column: 1 / 3;
  grid-row: 3;
}

.item5 {
  grid-column: 1 / 3;
  grid-row: 4;
}

.task-form select {
  border: 2px solid #d0d0d0;
  padding: 10px 12px;
  border-radius: 6px;
  width: 100%;
}

.data-time {
  width: 50px;
}

.task-form textarea {
  border: 2px solid #d0d0d0;
  padding: 6px 12px;
  border-radius: 6px;
  width: 100%;
}

.task-form label {
  display: block;
  width: 100%;
}

.btn-container {
  display: flex;
  justify-content: center;
}

.task-button {
  width: 80%;
}

.taskModal-input {
  width: 220px;
}

.taskModal-input input {
  padding: 10px;
}
