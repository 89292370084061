.meal {
  position: relative;
  right: 230px;
  color: red;
}
#datePicker {
  width: 170px !important;
}
#datePicker div {
  height: 50px !important;
}

.mine {
  display: none;
}

.targetcalories {
  border: none;
  border-radius: 8px;

  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  padding: 1em;
  margin-bottom: 2em;
}

.instructionsWorkout {
  border: 1px solid lightgray;
  border-radius: 6px;

  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12); */
  padding: 10px 8px;
  margin-bottom: .7em;
}

#waterQty {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
}

.template {
  display: flex;
  justify-content: space-between;
  border: none;

  padding: 1em;
  margin-bottom: 2em;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
}

.waterinfo {
  display: flex;
  width: 100%;
  gap: 1em;
}

@media (max-width: 1300px) and (min-width: 700px) {
  .meal {
    position: relative;
    right: 100px;
  }
  .targetCalorie {
    position: relative;
    left: 10px;
    right: 60px;
  }
  .dietGrid {
    position: absolute;
    right: 10px;
    left: 10px;
  }
  #timePicker {
    position: relative;
    left: 100px;
  }
}
