.container-list {
  padding: 46px;
  margin: -1% 1%;
}

.alloc-btn {
  float: right;
}
.alloc-table {
  margin-top: 38px;
}
