.new-client {
  width: 100%;
  margin-top: 50px;
  padding-left: 50px;
  padding-right: 85px;
}

.new-cient-container {
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
}

.new-cient-container h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.sub-margin {
  margin-bottom: 20px;
}

.client-row {
  display: flex;
  gap: 20px;
}

.client-col label {
  margin-bottom: 5px;
  display: block;
}

.client-col.btn-container {
  align-self: flex-end;
}

.delete-icon {
  align-self: center;
  cursor: pointer;
  display: flex;
  gap: 20px;
}

.client-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-delete,
.lead-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 1000;
  padding: 1rem;
}

.client-delete {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.client-delete h4 {
  font-size: 20px;
}

.delete-container {
  display: flex;
  gap: 20px;
}

.table-icon-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-self: flex-end;
}

.add-lead {
  cursor: pointer;
}

.lead-form {
  width: 1000px;
  height: 636px;
  overflow-y: scroll;
}

.lead-form button {
  float: right;
}

.lead-button {
  margin-top: 20px;
  padding: 6px 12px;
}

.global-search {
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

/*  global date input style */

.dateForm input {
  padding: 9px 10px;
}
