.workoutTemplate {
  background-color: #fff;
  height: 1500px;
}

.back-btn {
  border: none;
  background-color: #2d2d2d;
  color: #fff;
  border-radius: 6px;
  padding: 8px 16px;
}
