/* #singleLine{
    position: relative;
    left: 50px;
    bottom: 10px;
    color: red;
} */
@media (max-width: 1000px) and (min-width: 500px) {
  .singleLine {
    position: relative;
    left: 100px;
    bottom: 25px;
  }
}

.backdrop-form {
  width: 100%;
  height: 200vh;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100 !important;
}
