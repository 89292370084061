.selectedFood{
    border: 1px solid black;
    border-radius: 150px;
    padding: 5px;
    background-color: #c8cacf4f;
    color: rgb(44, 41, 41);
    outline: #fff;
    border-color:#c8cacf4f ;
}

.alignRight{
    margin-left: 800px;
   position: absolute;
   top: 10px;
   left: 200px;
}