.backdrop {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.379);
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100;
}

.card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  /* height: 636px; */
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-title {
  margin-bottom: 15px;
}
.payment-title h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

.form {
  width: 100%;
}

.form input,
.form select {
  border: 2px solid #d0d0d0;
  padding: 6px 12px;
  border-radius: 6px;
  width: 220px;
}

.form label {
  color: #2d2d2d;
  font-weight: 500;
}

.btn-create-link {
  width: 80%;
  font-size: 18px;
  padding: 5px 10px;
  color: #fff;
  background: #2d2d2d;
  border-radius: 6px;
}

.copy-link {
  font-size: 18px;
  font-weight: 600;
  color: #2d2d2d;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.btn-continue {
  /* font-size: 18px;
    padding: 6px 12px;
    color: #fff; 
    background: #2D2D2D;
    border-radius: 6px;
    margin-bottom: 20px; */

  padding: 5px 24px;
  color: #fff;
  background: #2d2d2d;
  border: 2px solid #2d2d2d;
  margin-right: 30px;
  border-radius: 6px;
}

.btn-cancel {
  font-size: 18px;
  padding: 5px 12px;
  color: #2d2d2d;
  background-color: #fff;
  border: 2px solid #2d2d2d;
  border-radius: 6px;
  margin-right: 20px;
}
