
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

:root {
  --primary-ff: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--primary-ff);
}
.header {
  width: 100%;
  height: 1125px;
  background-color: rgb(0, 0, 0, 0.9);
  color: white;
}
.first-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 250px;
}

.title-light {
  color: white;
  font-weight: 200;
  font-size: 1rem;
  padding-left: 5.5rem;
  letter-spacing: 1.5px;
  opacity: 0.7;
}

.title-heading {
  color: white;
  font-size: 6.2rem;
  font-weight: 900;
  padding-left: 5.5rem;
  word-spacing: 1px;
  line-height: 100px;
  width: 90px;
}
.logo {
  padding-top: 3rem;
  margin-bottom: 15rem;
}
.food-image-container {
  position: relative;
}
.food-image {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 35% 50%;
  width: 300px;
  height: 450px;
  z-index: 2;
  margin-left: 396px;
  margin-top: -37px;
}
.absolute-image {
  position: absolute;
  top: 4rem;
  right: 4rem;
  width: 300px;
  height: 450px;
  border: 3px solid white;
  z-index: 1;
  margin-top: -20px;
}
.header-table {
  position: relative;
  margin: auto;
  background-color: rgba(44, 41, 41, 0.759);
  border-radius: 15px;
  width: 90%;
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.weighter {
  font-weight: 400;
}
.bolder {
  font-weight: 700;
}
.primary-details {
  position: relative;
  margin: auto;
  margin-top: 50px;
  background-color: rgb(242, 242, 242);
  border-radius: 15px;
  width: 90%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
}
.primary-details div {
  font-size: 1rem;
  margin: auto;
}
.absolute-table {
  position: absolute;
  top: 0px;
  width: 75px;
  height: 75px;
  background-color: #333333;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 100%;
  z-index: 0;
}
.absolute-table-primary-details {
  position: absolute;
  top: 0px;
  width: 75px;
  height: 75px;
  background-color: rgb(238, 238, 238);
  border-top-left-radius: 15px;
  border-bottom-right-radius: 100%;
  z-index: 0;
}
.left-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  z-index: 1;
}
.left-table-header div span {
  margin-bottom: 50px;
}
.nutritional-information {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}
.nutritional-information div {
  background-color: rgb(242, 242, 242);
  color: black;
  font-size: 0.9rem;
  padding: 10px 20px;
  width: 30%;
  border-radius: 10px;
}
.header-table div {
  margin: auto;
  font-weight: 300;
  line-height: normal;
}
.result {
  font-weight: 100;
  color: rgb(0, 0, 0, 0.7);
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
.table-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
}
.nutrition {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.table-head {
  background-color: blanchedalmond;
  color: black;
  font-weight: 300;
  font-size: 40.5px;
  border: 1px solid black;
  text-align: center;
}
.table-head tr th {
  padding: 10px;
}
.nutrition-data {
  margin: 1rem 0;
  font-size: 1.5rem;
  color: black;
  width: 80%;
  display: flex;
  justify-content: space-between;
}
strong {
  font-weight: 600;
}
.diet_Plan_Details {
  display: block;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.diet_Plan_Details_id {
  margin-bottom: 5rem;
  margin-left: 2rem;
}

.personal_data {
  background-color: brown;
  color: #fff;
}

.dietTable {
  width: 90%;
  border: 1px solid black;
  border-spacing: 0;
  border-radius: 0 10px 10px 10px;
}

.dietTable tr:first-child th {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 1.5rem;
  text-align: left;
  color: #fff;
  background-color: rgb(0, 0, 0, 0.9);
}
.dietTable tr:first-child th:last-child {
  border-radius: 0 10px 0 0;
}
.dietTable tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}
.dietTable tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}
.dietTable tr:last-child {
  margin-bottom: 3rem;
}
.dietTable td {
  border-right: 1px solid black;
}
.special-row {
  font-size: 1rem;
  border-bottom: 1px solid black;
  text-align: center;
}
th {
  font-size: 1rem;
}
table,
tr,
td {
  font-size: 0.9rem;
}
strong {
  color: black;
}
td {
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px;
  width: fit-content;
}
.and-condition {
  color: rgb(0, 0, 0, 0.6);
}
.imageborder {
  padding: 10px;
}
b {
  font-weight: bolder;
}
.colored {
  background-color: yellow;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  height: 20%;
}

.conclusion {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  margin-bottom: 3rem;
  width: 90%;
}
.conclusion_title {
  font-size: 1rem;
  font-weight: bold;
  margin: 3rem 0;
}
.conclusion div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.conclusion_header {
  font-weight: bold;
  font-size: 1.5rem;
  margin: 3rem 0;
  width: 90%;
}
.bordered-image {
  width: 14rem;
  height: 14rem;
}
.rotated {
  transform: rotate(240deg);
}
.epilogue {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 3rem;
}
.vegetables-container {
  width: 90%;
  margin: 2rem 0 1.5rem 0;
}
.vegetables {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 2rem;
}
.Instructions {
  font-weight: bold;
  font-size: 1rem;
}
p {
  font-size: 0.9rem;
  color: rgb(0, 0, 0, 0.9);
}
.general-instructions {
  width: 90%;
}
.image-rotate {
  transform: rotate(45deg);
}
ul > li {
  list-style-type: none;
  padding-bottom: 0.5rem;
  font-size: 0.9rem;
}
