.task-container {
  width: 100%;
  margin-top: 10px;
  padding-left: 50px;
  padding-right: 85px;
}

.task-tab-container {
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
}

/* filter  */

.filter-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.filter-container h4 {
  font-size: 22px;
  margin: 0;
}

.filter-box {
  display: flex;
  align-items: center;
  gap: 20px;
}

.row-1 input {
  font-size: 15px;
  /* width: 00px; */
  padding: 9px 10px;
  border-radius: 5px;
}

.selected-box {
  width: 220px;
}

/* ****** */

.react-select-container {
  width: 150px;
}

.react-select {
  outline: none;
  cursor: pointer;
}

.tabs {
  display: flex;
  gap: 35px;
}

.tab {
  cursor: pointer;
  margin-bottom: 20px;
  background-color: transparent;
}

.tab button {
  background-color: transparent;
  border: none;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.589);
  padding-bottom: 6px;
  border-bottom: 3px solid transparent;
  font-size: 18px;
}

.tab button.active,
.tab button:hover {
  color: black;
  border-bottom: 2px solid black;
}

.table-Container {
  height: 100vh;
}

.action-btn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.action-btn:hover {
  background-color: #f1f3f5fe;
}

.id_container {
  display: flex;
  gap: 15px;
}

.id_container span:nth-child(2) {
  cursor: pointer;
}

.comments {
  display: flex;
  gap: 20px;
}

.comment-icon {
  cursor: pointer;
}
