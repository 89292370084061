.wrapper {
  width: 100%;
  background-color: #f3f3f3;
}

.content {
  min-height: 250px;
  padding: 10px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 4px solid #d2d6de;
  margin-bottom: 10px;
  width: 100%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}

.box.box-primary {
  border-top-color: var(--activeSideBar);
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.impbtntitle {
  position: absolute;
  right: 10px;
  top: 10px;
}

.pull-right {
  float: right;
}

.bozero {
  border-top: 0 !important;
}

.pagetitleh-whitebg {
  background: #fff;
  margin: 0;
  font-size: 18px;
  font-family: Poppins-SemiBold, sans-serif;
  padding: 15px 10px;
  color: #444;
  border-bottom: 1px solid #d7dfe3;
}

.around10 {
  padding: 10px;
}

.border0 {
  border: 0 !important;
}

.bordertop {
  border-top: 1px solid #f4f4f4 !important;
}

.fs14 {
  font-size: 14px;
}

.fs16 {
  font-size: 16px;
}

.fs20 {
  font-size: 22px;
  font-weight: 500;
  font-family: Poppins-SemiBold, sans-serif;
}

.py10 {
  padding: 10px 0;
}

.pzero {
  padding: 0;
}

.pt0 {
  padding-top: 0 !important;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pb10 {
  padding-bottom: 10px;
}

.pt25 {
  padding-top: 25px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.ml-5 {
  margin-left: 5px;
}
.ml-3 {
  margin-left: 3px;
}
.ml-2 {
  margin-left: 2px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-3 {
  margin-right: 2px;
}
.mr-2 {
  margin-right: 3px;
}
.ml50 {
  margin-left: 50px;
}
.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt20 {
  margin-top: 20px;
}

.my10 {
  margin: 10px 0;
}

.additionalInputArea {
  display: flex;
}

.additionalInputArea > input {
  flex-basis: 95%;
}

.switch-inline > label {
  float: left;
  width: 150px;
  padding-right: 15px;
}

.material-switch > input[type="checkbox"] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
}

.material-switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: "";
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}

.material-switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: "";
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}

.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}

.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}

.bg-green,
.callout.callout-success,
.label-success,
.modal-success .modal-body {
  background-color: #66aa18 !important;
}

@media (min-width: 1200px) {
  .col20 {
    width: 20%;
  }
}

.tshadow {
  border: 1px solid #e7ebf0;
}

.text-right {
  text-align: right;
}

.mx5p .form-group {
  margin: 0 5rem 15px 5rem;
}

.form-group {
  margin-bottom: 15px;
}

.form-group .req {
  color: #fc2d42;
}

.form-group label {
  font-size: 0.9rem;
  font-family: Poppins-Medium, sans-serif;
  font-weight: normal;
}

.form-group .radio-inline {
  white-space: nowrap;
  overflow: hidden;
}

.form-group .radio-inline input {
  margin: 0 10px;
}

.form-group .control-label {
  text-align: right;
}

.form-group .form-control {
  border-radius: 0;
  box-shadow: none;
  padding: 0.3rem 0.5rem;
  font-size: 0.9rem;
  font-family: Poppins-Regular, sans-serif;
}

.form-group .form-control:focus {
  box-shadow: none;
  border: 1px solid #3c8dbc;
}

.form-group select {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}

.form-group .anchorbtn {
  background: transparent !important;
  border: 0 !important;
  color: #0084b4 !important;
  font-size: 14px;
  box-shadow: none !important;
}

.anchorbtn:focus,
.anchorbtn:hover {
  outline: 0 !important;
  background: transparent !important;
  border: 0 !important;
  color: #000 !important;
  text-decoration: none;
}

.form-group .pagetitleh2,
.bozero .pagetitleh2 {
  background: #f2f2f2;
  margin: 0;
  font-size: 18px;
  font-family: Poppins-SemiBold, sans-serif;
  padding: 15px 10px;
  color: #000;
  /* box-shadow: 0 0px 2px rgb(0 0 0 / 20%); */
  border-bottom: 1px solid #d7dfe3;
}

.addmoredetail-title {
  background: #f2f2f2;
  margin: 0;
  font-size: 18px;
  padding: 10px 14px;
  color: #444;
}

.boxplus {
  width: 100%;
  font-size: 18px;
  font-family: Poppins-SemiBold, sans-serif;
  background: transparent;
  text-align: left !important;
  outline: 0 !important;
  box-shadow: none !important;
  padding: 0;
  border: 0;
  color: #444;
  transition: all 0.5s ease-in-out;
}

.boxplus i {
  float: right;
  vertical-align: top;
  font-size: 14px;
  color: #97a0b3;
  padding-top: 8px;
  pointer-events: none;
}

.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
}

.box-body-collapsed {
  display: none;
}

.checkbox,
.radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.box-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #f4f4f4;
  position: relative;
  padding: 10px 10px 45px 10px;
  background-color: #fff;
}

.box-footer button {
  background-color: #727272;
  border-color: #525252;
  color: #fff;
  font-family: Poppins-Medium;
  border: none;
}

.box-footer button:hover {
  background-color: #333;
  border-color: #333;
  color: #fff;
}

.box-header {
  color: #444;
  display: block;
  padding: 15px 10px 10px 15px;
  font-family: Poppins-Regular, sans-serif;
  position: relative;
}

.box-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}

.download_label {
  display: none;
}

.dataTable {
  empty-cells: hide;
  color: #444;
}

.dataTable tbody tr {
  border: 1px solid #ddd;
}

.dataTables_wrapper .dataTables_maxRecords {
  float: left;
  margin-left: 20px;
}

.dataTables_wrapper .dataTables_maxRecords label {
  display: inline-block;
}

.dataTables_wrapper .dataTables_maxRecords select {
  display: inline-block;
  border-radius: 5px;
  font-weight: normal;
  border: 1px solid #ddd;
  outline: none;
  padding: 7px 0;
  text-align: center;
  font-size: 16px;
  width: 75px;
  margin-right: 10px;
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  margin-right: 20px;
}

div.dataTables_wrapper div.dataTables_filter input {
  display: inline-block;
  width: 250px;
  border-radius: 5px;
  font-weight: normal;
  border: 1px solid #ddd;
  outline: none;
  padding: 5px 10px;
  font-size: 16px;
}

a.dt-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 3px 8px;
  border-radius: 0px;
  cursor: pointer;
  font-size: 16px;
  color: #444;
  white-space: nowrap;
  overflow: hidden;
  background-color: #fff;
  text-decoration: none;
  outline: none;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

a.dt-button:hover {
  background-color: #ddd;
}

.dataTable tbody {
  background-color: #fff;
}

.table > thead > tr > th {
  background: #fff;
  vertical-align: top;
  color: #444;
  font-family: Poppins-Regular, sans-serif;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #dddddd;
}

.dataTables_empty {
  color: #f7bbbb;
}

.box-header h3.box-title i {
  display: none;
}

.box-header h3.box-title {
  font-size: 18px;
  font-family: Poppins-SemiBold, sans-serif;
}

.box-header > .box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}

.form-group .btn-primary,
.box-tools .btn-primary,
.bozero .btn-primary {
  background-color: #727272;
  border-color: #525252;
  transition: 0.3s;
  color: white;
  padding: 0.375rem 0.75rem;
}

.form-group .btn-primary:hover,
.box-tools .btn-primary:hover,
.bozero .btn-primary:hover {
  background-color: #070707;
  color: white;
}

.ptbnull {
  border-bottom: 1px solid #f4f4f4;
}

.nav-tabs-custom {
  margin-bottom: 20px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.nav-tabs-custom > .nav-tabs {
  margin: 0;
  border-bottom-color: #f4f4f4;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.nav-tabs-custom > .nav-tabs > li:first-of-type {
  margin-left: 0;
}

.nav-tabs-custom > .nav-tabs > li.active {
  border-top-color: var(--activeSideBar);
}

.nav-tabs-custom > .nav-tabs > li.active {
  border-bottom-color: var(--activeSideBar);
}

.nav-tabs-custom > .nav-tabs > li {
  border-bottom: 3px solid transparent;
  margin-bottom: -2px;
  margin-right: 5px;
  padding: 10px 15px;
}

.nav-tabs-custom > .nav-tabs > li:first-of-type.active > a {
  border-left-color: transparent;
}

.nav-tabs-custom > .nav-tabs > li.active > a {
  border-top-color: transparent;
  border-left-color: #f4f4f4;
  border-right-color: #f4f4f4;
}

.nav-tabs-custom > .nav-tabs > li.active > a,
.nav-tabs-custom > .nav-tabs > li.active:hover > a {
  background-color: #fff;
  color: #444;
}

.nav-tabs-custom > .nav-tabs > li > a,
.nav-tabs-custom > .nav-tabs > li > a:hover {
  background: transparent;
  margin: 0;
}

.nav-tabs-custom > .nav-tabs > li > a {
  color: #444;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  padding: 10px 15px;
}

.nav-tabs-custom > .tab-content {
  background: #fff;
  padding: 10px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.tablists {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tablists li {
  display: block;
  border-bottom: 1px solid #ddd;
  padding-left: 10px;
}

.tablists li button:hover {
  color: #0084b4;
}

.tablists li button.active {
  color: #0084b4;
  font-weight: 600;
}

.tablists li button {
  width: 100%;
  text-align: start;
  color: #444;
  padding: 10px;
  display: block;
  text-decoration: none;
  font-family: Poppins-Regular, sans-serif;
  font-size: 14px;
  background-color: transparent;
  border: none;
}

.reportlists {
  margin: 0;
  border-top: 1px solid #ddd;
}

.reportlists > button {
  padding: 10px 0;
  outline: none;
  border: none;
  background-color: #fff;
  text-align: start;
  font-family: Poppins-Medium, sans-serif;
  transition: all 0.25s ease-in-out;
  color: #444;
  font-size: 0.9rem;
}

.reportlists > button:hover {
  color: #3e588a;
}

.reportlists > .active {
  background-color: #dceeff;
  color: #333;
}

.reportlists > button > i {
  padding: 0 10px;
}

.bodyDataFields {
  border: 0;
  padding: 0 10px;
  line-height: 28px;
  border-bottom: 1px solid #ddd !important;
  empty-cells: show;
}

.dtMultiData {
  line-height: 24px;
}

.bodyDataActions {
  text-align: end;
  min-width: calc(1.5rem + 3 * 28px);
  width: calc(1.5rem + 3 * 28px);
  border-left: none;
  text-align: end;
  border-bottom: 1px solid #ddd !important;
}

.bodyDataActions > .btn-xs {
  padding: 0.25rem 0.5rem;
  line-height: 1.5rem;
}

.dtBottomSection {
  width: 100%;
  margin: auto;
  padding: 10px 0;
}

.dtPageDetails {
  height: 40px;
  line-height: 40px;
}

.dtPaginationArea {
  text-align: end;
  height: 40px;
}

.dtPageBtn {
  background-color: transparent;
  border: 0;
  color: #666;
  padding: 0 10px;
}

.dtPageBtn > i {
  pointer-events: none;
}

.dtPageBtn.disabled {
  pointer-events: none;
}

#dtCurrentPage {
  display: inline-block;
  background-color: #eee;
  padding: 7px 10px;
  font-family: Poppins-SemiBold, sans-serif;
  font-size: 16px;
  text-align: center;
  color: #666;
  margin: 0 5px;
  cursor: default;
}

/* Progress in Dashboard */

.topProgressStart {
  padding: 5px 10px 15px 10px;
  box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11),
    0 4px 16px 0 rgba(167, 175, 183, 0.33);
  border-radius: 0.35rem;
  background: #fff;
  margin-bottom: 10px;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.topProgressStart p i {
  padding-right: 5px;
  font-size: 10px;
}

.topProgressStart:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transform: translateY(-4px) scale(1.02);
  box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
}

.progress,
.progress > .progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress,
.progress > .progress-bar,
.progress .progress-bar,
.progress > .progress-bar .progress-bar {
  border-radius: 1px;
}

.progress.sm,
.progress-sm {
  height: 10px;
}

.progress.sm,
.progress-sm,
.progress.sm .progress-bar,
.progress-sm .progress-bar {
  border-radius: 1px;
}

.progress.xs,
.progress-xs {
  height: 7px;
}

.progress.xs,
.progress-xs,
.progress.xs .progress-bar,
.progress-xs .progress-bar {
  border-radius: 1px;
}

.progress.xxs,
.progress-xxs {
  height: 3px;
}

.progress.xxs,
.progress-xxs,
.progress.xxs .progress-bar,
.progress-xxs .progress-bar {
  border-radius: 1px;
}

.progress.vertical {
  position: relative;
  width: 30px;
  height: 200px;
  display: inline-block;
  margin-right: 10px;
}

.progress.vertical > .progress-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.progress.vertical.sm,
.progress.vertical.progress-sm {
  width: 20px;
}

.progress.vertical.xs,
.progress.vertical.progress-xs {
  width: 10px;
}

.progress.vertical.xxs,
.progress.vertical.progress-xxs {
  width: 3px;
}

.progress-group .progress-text {
  font-family: "Poppins-Bold";
}

.progress-group .progress-number {
  float: right;
}

.table tr > td .progress {
  margin: 0;
}

.progress-bar-light-blue,
.progress-bar-primary {
  background-color: #3c8dbc;
}

.progress-striped .progress-bar-light-blue,
.progress-striped .progress-bar-primary {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-green,
.progress-bar-success {
  background-color: #00a65a;
}

.progress-striped .progress-bar-green,
.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-aqua,
.progress-bar-info {
  background-color: #00c0ef;
}

.progress-striped .progress-bar-aqua,
.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-yellow,
.progress-bar-warning {
  background-color: #f39c12;
}

.progress-striped .progress-bar-yellow,
.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-red,
.progress-bar-danger {
  background-color: #dd4b39;
}

.progress-striped .progress-bar-red,
.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-minibar {
  height: 5px !important;
  margin-bottom: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Info box in Dashboard */

.info-box {
  display: block;
  min-height: 60px;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-bottom: 10px;
  box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11),
    0 4px 16px 0 rgba(167, 175, 183, 0.33);
  border-radius: 0.35rem;
  padding: 10px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: block;
  cursor: pointer;
  border: solid 1px #dde4eb;
}

.info-box:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transform: translateY(-4px) scale(1.02);
  box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
}

.info-box a {
  color: #333;
  text-decoration: none;
  transition: all 0.3s linear;
  width: 100%;
  display: block;
  padding: 10px;
}

.info-box small {
  font-size: 14px;
}

.info-box .progress {
  background: rgba(0, 0, 0, 0.2);
  margin: 5px -10px 5px -10px;
  height: 2px;
}

.info-box .progress,
.info-box .progress .progress-bar {
  border-radius: 0;
}

.info-box .progress .progress-bar {
  background: #fff;
}

.info-box-icon {
  /* border-top-left-radius:2px; */
  /* border-top-right-radius:0; */
  /* border-bottom-right-radius:0; */
  /* border-bottom-left-radius:2px; */
  display: block;
  float: left;
  height: 60px;
  width: 60px;
  text-align: center;
  font-size: 32px;
  line-height: 60px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  -webkit-box-shadow: 0px 9px 19px -9px rgba(229, 58, 54, 0.65);
  -moz-box-shadow: 0px 9px 19px -9px rgba(229, 58, 54, 0.65);
  box-shadow: 0px 8px 10px -9px rgba(53, 51, 51, 0.67);
  /* border-radius: 100%; */
  border: 2px solid #fff inset;
}

.info-box-icon > img {
  max-width: 100%;
}

.info-box-content {
  padding: 5px 10px;
  margin-left: 65px;
}

.info-box-number {
  display: block;
  font-family: "Poppins-Bold";
  font-size: 18px;
}

.progress-description,
.info-box-text {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-box-text {
  text-transform: uppercase;
}

.info-box-more {
  display: block;
}

.taskFieldArea {
  padding: 10px 0px 10px 10px;
}

.taskside {
  font-family: Poppins-Regular, sans-serif;
  padding: 10px;
  background-color: #f4f4f4;
}

.taskHeading {
  padding: 10px 0;
}

.taskHeading > .box-tools {
  width: 120px;
  margin-right: 20px;
}

.taskInfo {
  padding: 5px;
}

.taskInfo i {
  font-size: 18px;
  margin: 5px;
}

.taskInfo > div {
  font-size: 16px;
  margin: 10px 0;
}

.taskInfo span {
  font-weight: 700;
}

.timeline {
  position: relative;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
}

.timeline:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background: #ddd;
  left: 31px;
  margin: 0;
  border-radius: 2px;
}

.timeline > li {
  position: relative;
  /*margin-right:10px;*/
  margin-bottom: 15px;
  font-size: 14px;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-item {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-top: 0;
  background: #fff;
  color: #444;
  margin-left: 60px;
  /*margin-right:15px;*/
  padding: 0;
  position: relative;
}

.timeline-item > .time > button {
  color: #999;
  float: right;
  padding: 10px;
  font-size: 14px;
}

.timeline-item > .time > button:hover {
  color: #0073b7;
}

.timeline > li > .timeline-item > .timeline-header {
  margin: 0;
  color: #555;
  border-bottom: 1px solid #f4f4f4;
  padding: 10px;
  font-size: 16px;
  line-height: 1.1;
}

.timeline > li > .timeline-item > .timeline-header > a {
  /* font-weight:600 */
  font-family: "Poppins-Bold";
}

.timeline > li > .timeline-item > .timeline-body,
.timeline > li > .timeline-item > .timeline-footer {
  padding: 10px;
}

.timeline > li > .fas,
.timeline > li > .far,
.timeline > li > .ion {
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  position: absolute;
  color: #666;
  background: #d2d6de;
  border-radius: 50%;
  text-align: center;
  left: 18px;
  top: 0;
}

.timeline > .time-label > span {
  /* font-family:'Poppins-Bold'; */
  padding: 5px;
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  font-family: "Poppins-Medium";
  font-size: 12px;
}

.timeline-inverse > li > .timeline-item {
  background: #f0f0f0;
  border: 1px solid #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.timeline-inverse > li > .timeline-item > .timeline-header {
  font-family: Poppins-SemiBold, sans-serif;
  font-size: 18px;
  color: #0073b7;
  border-bottom-color: #ddd;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}

.wellscroll {
  height: 190px;
  overflow-y: auto;
  overflow-x: hidden;
}

.carousel-row {
  margin-bottom: 10px;
}

.slide-row {
  padding: 10px;
  background-color: #ffffff;
  min-height: 220px;
  border: 1px solid #e7e7e7;
  overflow: hidden;
  height: auto;
  position: relative;
}

.slide-carousel {
  width: 20%;
  float: left;
}

.slide-carousel .carousel-indicators {
  margin-bottom: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.slide-carousel .carousel-indicators li {
  border-radius: 0;
  width: 20px;
  height: 6px;
}

.slide-carousel .carousel-indicators .active {
  margin: 1px;
}

.carousel-inner > .item > img {
  width: 150px;
}

.slide-content {
  position: absolute;
  top: 0;
  left: 20%;
  display: block;
  float: left;
  width: 80%;
  max-height: 76%;
  padding: 1.5% 2% 2% 2%;
  overflow-y: auto;
}

.slide-content .textData {
  font-size: 16px;
  line-height: 26px;
  font-family: Poppins-Medium, sans-serif;
}

.slide-content h4 {
  color: #7bb3d2;
  padding: 5px 0;
  margin-bottom: 3px;
  margin-top: 0;
  line-height: normal;
}

.slide-footer {
  position: absolute;
  bottom: 0;
  left: 20%;
  width: 78%;
  height: 20%;
  margin: 1%;
}

#siblingName {
  padding: 5px 10px;
  border-radius: 5px;
}

/*LIVE MEETING MODAL*/

.staffmain {
  border: 1px solid #ddd;
  margin-left: 15px;
}

.liststaff {
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 215px;
  overflow: auto;
}

.liststaff li {
  position: relative;
  display: block;
  margin-left: 15px;
  padding: 0 0px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 0;
}

/*DASHBOARD STYLES*/

.box-left {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-left: 4px solid #d2d6de;
  margin: 10px 0;
  width: 100%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  padding: 10px 0;
}

.box-left .iconArea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  margin: auto;
  border-radius: 45px;
}

.box-left i {
  color: white;
  font-size: 28px;
}

.box-left-blue {
  border-left-color: #35baf6;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.box-left-blue .iconArea {
  background-color: #35baf6;
}

.box-left-red {
  border-left-color: red;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.box-left-red .iconArea {
  background-color: red;
}

.box-left-green {
  border-left-color: green;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.box-left-green .iconArea {
  background-color: green;
}

.box-left-purple {
  border-left-color: purple;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.box-left-purple .iconArea {
  background-color: purple;
}

.pieChartArea {
  height: 100%;
}

.pieChartBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-info {
  font-weight: bolder;
}

.box-info span {
  font-size: medium;
  color: #35baf6;
}

.button-corner {
  text-align: end;
}

.overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overlay-opacity {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
}

.dialog {
  width: 400px;
  max-width: 100%;
  position: fixed;
  right: 0;
  top: 7%;
  transform: translate(-5%, -50%);
  z-index: 9999;
  background-color: #fefefe;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #888;
}

.dialog i {
  padding: 5px 0;
  font-size: 20px;
}

.dialog .errorMsg {
  font-size: 16px;
  line-height: 32px;
  padding-left: 20px;
  color: #333;
  font-family: Poppins-Regular, sans-serif;
}

.dialog .closeBtn {
  cursor: pointer;
  border: none;
  font-weight: bold;
  background-color: transparent;
}

.dialog .iconRed {
  color: red;
}

.dialog .iconGreen {
  color: green;
}

.confirmDialog {
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
  opacity: 1;
}

.confirmDialog .errorMsg {
  font-size: 20px;
  padding-left: 20px;
  padding-bottom: 25px;
  color: #333;
  font-family: Poppins-Regular, sans-serif;
}

/* ----------- Mobile Styling -------------*/

@media screen and (max-width: 575px) {
  #topHeader .brandTitle {
    font-size: large;
  }
  #topHeader > .profileArea {
    width: 125px;
  }
  #topHeader .dropDown {
    left: calc(-10rem + 105px);
    width: 10rem;
  }
  #topHeader .dropDown.notification-info-panel {
    left: calc(-18rem + 120px);
    width: 18rem;
  }
  #topHeader .dropDown > .option {
    font-size: 1rem;
    padding: 10px 5px;
  }
  .mx5p .form-group {
    margin: 0 1rem 15px 1rem;
  }
}

@media screen and (max-width: 370px) {
  #topHeader .brandTitle {
    font-size: small;
  }
}

.databaseImgArea {
  position: relative;
  width: 60px;
  height: 60px;
}

.databaseImgArea > button {
  position: absolute;
  right: 2px;
  top: -16px;
  background-color: red;
  border: none;
  color: white;
  font-size: 12px;
  line-height: 12px;
  padding: 0;
  border-radius: 2px;
  width: 16px;
  height: 16px;
}

.notification::after {
  margin: 0px;
  padding: 0px;
  font-family: Arial;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  top: -9px;
  right: -15px;
  padding: 5px 8px;
  line-height: 100%;
  border: 2px #fff solid;
  border-radius: 60px;
  background: #db3434;
  opacity: 0;
  content: attr(data-count);
  transform: scale(0.5);
  transition: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.notification.notify::before {
  animation: ring 1.5s ease;
}
.notification.show-count::after {
  transform: scale(1);
  opacity: 1;
}
.notification-info-panel > h3 {
  padding: 0 10px;
  font-size: large;
  font-weight: bolder;
}
.notification-info-panel > ul {
  margin: 0;
  padding: 0;
}
.notification-info-panel > ul li {
  list-style: none;
}
.notification-info-panel .notification-message {
  margin: 0 25px;
  padding: 4px;
  background-color: #ebebeb;
  margin-bottom: 3px;
  border: 1px solid #ececec;
  border-radius: 8px;
  height: 200px;
}
.notification-info-panel .notification-message .timestamp {
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 600;
}
.notification-info-panel .notification-message .content {
  font-size: 17px;
}
.notification-info-panel .notification-message.unread {
  background-color: #17a2b8;
  color: #fff;
}

.alert {
  padding-bottom: 30px;
}
.alert > .start-icon {
  margin-right: 0;
  min-width: 20px;
  text-align: center;
}

.alert > .start-icon {
  margin-right: 5px;
}

/*-------------- Profile --------------*/

/* ----------- Mobile Styling -------------*/

@media screen and (max-width: 575px) {
  #topHeader > .profileArea {
    width: 100px;
  }
  #topHeader .dropDown {
    left: calc(-10rem + 100px);
    width: 10rem;
  }
  #topHeader .dropDown > .option {
    font-size: 1rem;
    padding: 10px 5px;
  }
  .mx5p .form-group {
    margin: 0 1rem 15px 1rem;
  }
}

.topProfile {
  position: relative;
  display: flex;
  color: #000;
  font-family: Poppins-Regular, sans-serif;
}

.topProfile img {
  width: 150px;
  min-width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #eee;
  background-color: #eee;
  margin: auto;
  display: block;
  margin-top: 25px;
}
.topProfile > .info {
  padding: 25px;
}

.topProfile .info p {
  margin: 5px 0;
  font-size: 18px;
}

.info span {
  float: right;
}

.topProfile > .info > .more {
  display: flex;
  width: 100%;
  justify-content: space-around;
  text-align: center;
  padding-top: 20px;
}

.topProfile .profileEdit {
  position: absolute;
  bottom: -30px;
  right: 60px;
  background-color: #7cb342;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
}

.profileInfo {
  background-color: #fff;
  padding: 30px 20px 20px 20px;
}

.tabNavigation ul {
  border-bottom: 1px solid #ddd;
}
.tabNavigationForProfile ul {
  border-bottom: 1px solid #ddd;
}

.tabNavigation ul li {
  display: inline-block;
  text-decoration: none;
  padding: 10px 30px;
  margin: 0 5px;
  text-transform: uppercase;
  font-family: Poppins-SemiBold, sans-serif;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tabNavigationForProfile ul li {
  display: inline-block;
  text-decoration: none;
  padding: 10px 30px;
  margin: 0 5px;
  text-transform: capitalize;
  font-family: Poppins-SemiBold, sans-serif;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tabNavigation ul li.active {
  border-bottom: 3px solid #004979;
}

.tabNavigationForProfile ul li.active {
  border-bottom: 3px solid #004979;
}

.tabNavigation ul li:not(.active):hover {
  border-bottom: 3px solid #578fb4;
}

.tabNavigationForProfile ul li:not(.active):hover {
  border-bottom: 3px solid #578fb4;
}

.profileInfo .profileTab {
  position: relative;
  padding: 30px 20px;
}

/*--------------- Editable Field ---------------*/

.editableField {
  display: flex;
  color: #727272;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  margin: 0 10px;
}

.editableField > i {
  padding: 15px;
  font-size: 26px;
}

.editableField > div {
  width: 100%;
  padding-left: 10px;
}

.editableField input {
  border: none;
  border-bottom: 2px solid #727272;
  width: 90%;
  font-family: Poppins-SemiBold, sans-serif;
}

.editableField input:read-only {
  border: none;
  cursor: default;
}

.editableField input:focus {
  outline: none;
}
.ml-1 {
  margin-left: 5px;
}
.ml-2 {
  margin-left: 10px;
}
/*Client SIdebar*/
.clientSidebar {
  zoom: var(--ratio);
  zoom: var(--ratioP);
  /* height: calc(100vh * (1 / var(--ratio)) - 2 * var(--headerHeight) - 10px); */
  height: 100%;
  width: 100%;
  min-width: 60px;
  background-color: white;
  font-family: Poppins-Regular, sans-serif, "Open Sans";
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.5s ease-in-out;
  scrollbar-width: thin;
}
.clientSidebar .sideBarBtn {
  font-size: 1.2rem;
  color: #070707;
  font-weight: bold;
}
.clientSidebar .sideBarBtn:hover {
  background-color: var(--activeSideBar);
  color: #eee;
  border-left: 5px solid #070707;
}
