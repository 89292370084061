.workout-calendar-con {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-calendar {
  width: 100%;
  /* max-width: 800px; */
}

.centered-calendar.template {
  width: 80%;
  /* height: 1000px; */
}

.workout-calendar {
  width: 100% !important;
}

.fc-day {
  cursor: pointer;
}

.fc-day:hover {
  background-color: lightgray;
}

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 10px 4px;
}

.fc-today-button {
  /* border: none !important; */
  background-color: transparent !important;
  color: rgb(5, 5, 5) !important;
  font-weight: 700 !important;
  border: 1px solid rgb(5, 5, 5) !important;
}

.fc-prev-button,
.fc-next-button {
  border: none !important;
  background-color: transparent !important;
  color: rgb(5, 5, 5) !important;
  font-weight: 700 !important;
  /* border: 1px solid rgb(5, 5, 5) !important; */
  box-shadow: none !important;
}

.fc-prev-button:focus,
.fc-next-button:focus {
  border: 1px solid rgb(5, 5, 5) !important;
}

.fc-toolbar-chunk {
  margin-right: 100px !important;
}

.fc .fc-toolbar-title {
  margin-right: 20px !important;
}

.fc-col-header-cell-cushion {
  text-decoration: none;
  color: rgb(5, 5, 5) !important;
}

.fc-daygrid-day-number {
  text-decoration: none;
  color: rgb(5, 5, 5) !important;
}

.event-container {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.event-time {
  width: 50px;
  margin-right: 10px;
}

.event-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-btn {
  border: none;
  font-size: 15px;
  padding: 3px 6px;
  cursor: pointer;
  background-color: rgba(5, 5, 5, 0.636);
  color: white;
}

.snackbar-container {
  top: -70%;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
  font-weight: bold;
  position: fixed;
  z-index: 90000;
}
