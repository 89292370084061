.coment-title {
  font-size: 18px;
  font-weight: 600;
}

.comment-list {
  width: 100%;
  padding: 0;
}

.comment-list-item {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.comment-item-list {
  margin-bottom: 20px;
}

.comment-list-container {
  display: flex;
}

.comment-list-container span:first-child {
  margin-right: 20px;
}

.comment-list-container span:nth-child(2) {
  font-weight: 600;
}

.comment-list-container span:nth-child(3),
.comment-list-container span:nth-child(4),
.comment-list-container span:nth-child(5) {
  font-size: 12px;
  margin-left: 20px;
  margin-top: 5px;
  color: #6b6b6b;
}
.comment-list-container span:nth-child(4) {
  margin-left: 10px;
}

.comment-list-container span:nth-child(5) {
  margin-left: 20px;
}

.comment-list-container span:nth-child(6) {
  width: 100px;
  margin-left: 80px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}

.comment-text {
  margin-left: 50px;
  font-size: 14px;
}

.comment-item-icon {
  background-color: #f6f6f6;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment-input {
  flex: 1;
  padding: 6px;
  border-radius: 6px;
  border: none;
  background-color: #f6f6f6;
}

.comment-input:focus-visible {
  outline: none;
}

.comment-sent {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment-sent:hover {
  background-color: #f6f6f6;
}
