.backdrop-vmax {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.379);
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100;
}

.card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1250px;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;

  height: 600px;
  overflow-y: auto;
}

.menu-bar-con {
  width: 100%;
}

.menu-bar {
  width: 100%;
  list-style: none;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  /* border-bottom: 2px solid #ccc; */
}

.menu-item {
  cursor: pointer;
  padding: 10px;
  padding-bottom: 0px;
  position: relative;
}

.menu-item.active::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}
