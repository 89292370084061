.drop-down-menu {
  position: absolute;
  background-color: red;
  top: 70px;
  width: 700px;
  right: 0;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.drop-down-menu::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 25px;
  height: 20px;
  width: 20px;
  background: var(--secondary-bg);
  transform: rotate(45deg);
  background-color: #fff;
  box-shadow: -1px -2px 5px -1px rgba(0, 0, 0, 0.12);
}

.drop-down-title {
  padding: 25px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #d0d0d0;
}

.drop-down-title span:first-child {
  font-size: 22px;
  color: #2d2d2d;
  font-weight: 600;
}

.drop-down-title span:last-child {
  color: red;
  font-weight: 600;
}

.drop-down-menu ul {
  padding: 0;
}

.drop-down-menu li {
  padding: 25px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d0d0d0;
}

.icon-container {
  display: flex;
  gap: 20px;
}

.notification-message {
  display: flex;
  flex-direction: column;
}

.read:active {
  opacity: 50%;
}

.not-found {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 600;
  font-size: 20px;
}

.mark-read {
  color: #2d2d2d !important;
  background-color: none !important;
  cursor: default;
}
