.floatclass{
    float: right;
    margin-right: 20px;
}

.leftside_move{
    position: relative;
    left: 180px;
}

input.input-error,
select.input-error
{
      
    background-color: #fce4e4;
    border: 0.1px solid #ff01416a;
    outline: none;
}

label.checkbox-error,
.gender-error{
    color:red
}

p.error-message{
    color: red;
    display: flex;
    justify-content: center;
}

