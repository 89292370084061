/* ---------------------------- Top Header ----------------------------  */

#topHeader {
  height: calc(var(--headerHeight) + 10px);
  padding: 1rem;
  background-color: white;
  zoom: var(--ratio);
  zoom: var(--ratioP);
}

#topHeader > .brandTitle {
  color: var(--activeSideBar);
  font-size: 22px;
  font-family: Poppins-Medium, sans-serif;
  font-weight: bold;
  white-space: nowrap;
}

#topHeader .lastLogin {
  padding-left: 10px;
  padding-right: 0;
  white-space: nowrap;
  font-family: Poppins-Regular, sans-serif;
  width: 250px;
  margin-top: -7px;
  padding: 0 10px 0 20px;
}

#topHeader > .profileArea {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin-top: -7px;
  padding: 0 30px 0 20px;
  background-color: #fff;
  cursor: pointer;
  border-left: 1px solid #ddd;
}

#topHeader .userProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 1px 2px 4px #888 !important;
}

#topHeader .userInfo {
  padding-left: 30px;
  padding-right: 0;
  white-space: nowrap;
  font-family: Poppins-Regular, sans-serif;
}

#topHeader .headIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 40px;
  height: 40px;
  font-size: 16px;
  border: none;
  background-color: transparent;
}

#topHeader .headIcons > i {
  pointer-events: none;
}

#topHeader .dropDown {
  position: absolute;
  display: none;
  top: 60px;
  left: calc(-18rem + 250px);
  width: 18rem;
  z-index: 999;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 2px 3px #888 !important;
  padding: 10px 0px;
}

#topHeader .visible {
  display: block;
}

#topHeader .dropDown::before {
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  right: 15px;
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

#topHeader .dropDown::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  top: -10px;
  left: 0;
  background-color: transparent;
}

#topHeader .dropDown > .option {
  width: 100%;
  font-family: Poppins-Regular, sans-serif;
  font-size: 1rem;
  padding: 10px 20px;
}

#topHeader .dropDown > .option > button > i {
  font-size: 1.2rem;
  margin: 0 10px;
}

#topHeader .dropDown > .option > button {
  font-family: Poppins-Regular, sans-serif;
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: start;
}

#topHeader .dropDown > .option:hover {
  background-color: rgba(238, 238, 238, 0.5);
}

#topHeader .dropDown.notification-info-panel {
  position: absolute;
  display: none;
  top: 60px;
  height: 20rem;
  left: calc(-25rem + 290px);
  width: 25rem;
  z-index: 999;
  border-radius: 5px;
  overflow-y: scroll;

  background-color: #fff;
  box-shadow: 1px 2px 3px #888 !important;
}

#topHeader .dropDown.notification-info-panel.visible {
  display: block;
}

/* ---------------------------- Header ----------------------------  */

header {
  zoom: var(--ratio);
  zoom: var(--ratioP);
  background-color: var(--sideBarBg);
  height: var(--headerHeight);
  font-family: sans-serif, "Open Sans", "Helvetica Neue";
  font-weight: 500;
}

.navTitle {
  width: 0;
  height: var(--headerHeight);
  line-height: var(--headerHeight);
  float: left;
  transition: all 0.5s ease-in-out;
  color: white;
  font-size: 0.9rem;
  font-family: Poppins-Medium, sans-serif, "Helvetica Neue";
  overflow: hidden;
}

.menuArea {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  border: none;
  background-color: var(--sideBarBg);
  padding: 0;
  box-shadow: -2px -5px 30px -8px rgba(0, 0, 0, 0.51) inset;
  -webkit-box-shadow: -2px -5px 30px -8px rgba(0, 0, 0, 0.51) inset;
  -moz-box-shadow: -2px -5px 30px -8px rgba(0, 0, 0, 0.51) inset;
}

.menu_btn {
  height: var(--headerHeight);
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  pointer-events: none;
}

.menu_btn_burger {
  width: 2em;
  height: 4px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
  transition: all 0.5s ease-in-out;
}

.menu_btn_burger::before,
.menu_btn_burger::after {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 4px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
  transition: all 0.5s ease-in-out;
}

.menu_btn_burger::before {
  transform: translate(-12px, -8px);
}

.menu_btn_burger::after {
  transform: translate(-12px, 8px);
}

.menu_btn.menuOpen .menu_btn_burger {
  transform: translateX(-40px);
  background: transparent;
  box-shadow: none;
}

.menu_btn.menuOpen .menu_btn_burger::before {
  transform: rotate(45deg) translate(20px, -20px);
}

.menu_btn.menuOpen .menu_btn_burger::after {
  transform: rotate(-45deg) translate(20px, 20px);
}

.pageName {
  color: white;
  line-height: var(--headerHeight);
  font-size: 0.9rem;
  font-family: Poppins-Medium, sans-serif, "Helvetica Neue";
  margin-left: 20px;
}

/* ---------------------------- SideBar ----------------------------  */

#menu_section {
  zoom: var(--ratio);
  zoom: var(--ratioP);
  height: calc(100vh * (1 / var(--ratio)) - 2 * var(--headerHeight) - 10px);
  width: 60px;
  min-width: 60px;
  padding-left: 5px;
  background-color: var(--sideBarBg);
  font-family: Poppins-Regular, sans-serif, "Open Sans";
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.5s ease-in-out;
  scrollbar-width: thin;
}

*::-webkit-scrollbar-track {
  background: #eee;
}

*::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
}

button > i,
a > i {
  pointer-events: none;
}

#menu_section .accordion-item {
  border: none;
  background-color: var(--sideBarBg);
  overflow: hidden;
}

#menu_section .accordion-button {
  font-size: 0.9rem;
  font-weight: 340;
  white-space: nowrap;
  font-family: Poppins-Regular, sans-serif, "Open Sans";
  color: #fff;
  background-color: var(--sideBarBg);
}

#menu_section .accordion-button:hover,
.sideBarBtn:hover {
  background-color: #070707;
  color: var(--activeSideBar);
  border-left: 5px solid var(--activeSideBar);
}

#menu_section .accordion-button > i,
#menu_section .accordion-button > b {
  pointer-events: none;
}

#menu_section .accordion-button:not(.collapsed) {
  background-color: #070707;
  border-left: 5px solid var(--activeSideBar);
  box-shadow: none;
}

#menu_section .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-size: 14px 14px;
  height: 14px;
  width: 14px;
  transform: rotate(90deg);
}

#menu_section .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(0deg);
}

#menu_section .accordion-button:focus {
  z-index: 3;
  box-shadow: none !important;
}

#menu_section .accordion-button > i,
.sideBarBtn > i {
  margin-right: 25px;
}

#menu_section .accordion-body {
  padding: 0;
}

.sideBarBtn {
  position: relative;
  display: flex;
  color: #fff;
  background-color: var(--sideBarBackground);
  align-items: center;
  white-space: nowrap;
  width: 100%;
  padding: 1rem 1.25rem;
  text-decoration: none;
  text-align: left;
  font-size: 0.9rem;
  font-weight: 340;
  font-family: Poppins-Regular, sans-serif, "Open Sans";
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.sideBarSubBtn {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  padding: 7px 0;
  padding-left: 30px;
  font-size: 0.85rem;
  text-decoration: none;
  color: #fff;
  text-align: left;
  background-color: #282828;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.sideBarSubBtn:hover {
  color: var(--activeSideBar);
}

.sideBarSubBtn > i {
  font-size: 12px;
  margin-right: 10px;
}

/* ---------------------------- Page Area ----------------------------  */

#page_section {
  position: relative;
  width: 100%;
  transition: all 0.5s ease-in-out;
  background-color: #eee;
  overflow-x: hidden;
  zoom: var(--ratio);
  zoom: var(--ratioP);
  height: calc(100vh * (1 / var(--ratio)) - 2 * var(--headerHeight) - 10px);
  scrollbar-width: thin;
}

/* ---------------------------- Login ----------------------------  */

#loginSection {
  position: relative;
  width: 100%;
  transition: all 0.5s ease-in-out;
  background-color: #eee;
  zoom: var(--ratio);
  zoom: var(--ratioP);
  height: calc(100vh * (1 / var(--ratio)));
  display: flex;
  align-items: center;
}

.login_container {
  width: 30%;
  min-width: 400px;
  margin: 0 auto;
}

.login_heading {
  font-size: 28px;
  color: #555;
  font-family: Poppins-Medium, sans-serif;
  margin-bottom: 5px;
}

.login_subHeading {
  font-size: 16px;
  color: #555;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif;
}

.login_card {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 5px 10px 8px #888888;
  border-top: 5px solid #515adb;
  margin-top: 60px;
}

.login_card::before {
  float: right;
  content: "\f007   Sign in";
  position: relative;
  top: -50px;
  height: 50px;
  line-height: 50px;
  width: 100px;
  text-align: center;
  font-family: "Font Awesome 5 Pro", Poppins-Regular, sans-serif;
  background-color: #515adb;
  color: white;
  border-radius: 4px 4px 0 0;
}

.login_header {
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 400;
  margin: 20px 10px;
  color: #555;
  font-family: Poppins-Medium, sans-serif, "Open Sans";
}

.login_card > form {
  padding: 2% 7%;
}

.loginFormField {
  margin: 10px auto;
  width: 100%;
}

.loginFormField label {
  font-size: 16px;
  font-family: Poppins-Regular, sans-serif, "Open Sans";
  color: #777;
}

.loginField {
  padding-left: 0;
  font-size: 16px;
  font-family: Poppins-Regular, sans-serif, "Open Sans";
  border: none;
  border-bottom: 1px solid #999;
  border-radius: 0;
}

.inputIcon {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  padding: 1.1em;
  color: #777;
}

.loginField:focus {
  box-shadow: none;
  border: 1px solid #515adb;
  border-bottom-width: 4px;
}

.loginField:focus ~ .inputIcon {
  color: #515adb;
}

#loginBtn {
  padding: 10px 15px;
  background-color: #515adb;
  border: none;
  font-size: 16px;
  font-family: Poppins-Regular, sans-serif, "Open Sans";
  margin: 10px 0;
  float: right;
  color: white;
}

/* ------------------------ Background ------------------------ */

.bg-red,
.bg-yellow,
.bg-aqua,
.bg-blue,
.bg-light-blue,
.bg-green,
.bg-navy,
.bg-teal,
.bg-olive,
.bg-lime,
.bg-orange,
.bg-fuchsia,
.bg-purple,
.bg-maroon,
.bg-black,
.bg-red-active,
.bg-yellow-active,
.bg-aqua-active,
.bg-blue-active,
.bg-light-blue-active,
.bg-green-active,
.bg-navy-active,
.bg-teal-active,
.bg-olive-active,
.bg-lime-active,
.bg-orange-active,
.bg-fuchsia-active,
.bg-purple-active,
.bg-maroon-active,
.bg-black-active,
.callout.callout-danger,
.callout.callout-warning,
.callout.callout-info,
.callout.callout-success,
.alert-success,
.alert-danger,
.alert-error,
.alert-warning,
.alert-info,
.label-danger,
.label-info,
.label-warning,
.label-primary,
.label-success,
.modal-primary .modal-body,
.modal-primary .modal-header,
.modal-primary .modal-footer,
.modal-warning .modal-body,
.modal-warning .modal-header,
.modal-warning .modal-footer,
.modal-info .modal-body,
.modal-info .modal-header,
.modal-info .modal-footer,
.modal-success .modal-body,
.modal-success .modal-header,
.modal-success .modal-footer,
.modal-danger .modal-body,
.modal-danger .modal-header,
.modal-danger .modal-footer {
  color: #fff !important;
}

.bg-gray {
  color: #000;
  background-color: #d2d6de !important;
}

.bg-gray-light {
  background-color: #f7f7f7;
}

.bg-black {
  background-color: #111 !important;
}

.bg-red,
.callout.callout-danger,
.alert-error,
.label-danger,
.modal-danger .modal-body {
  background-color: #e91e63 !important;
}

.bg-yellow,
.callout.callout-warning,
.alert-warning,
.label-warning,
.modal-warning .modal-body {
  background-color: #ff9801 !important;
}

.bg-aqua,
.callout.callout-info,
.alert-info,
.label-info,
.modal-info .modal-body {
  background-color: #16b1c5 !important;
}

.bg-blue {
  background-color: #0073b7 !important;
}

.bg-light-blue,
.label-primary,
.modal-primary .modal-body {
  background-color: #3c8dbc !important;
}

.bg-green,
.callout.callout-success,
.label-success,
.modal-success .modal-body {
  background-color: #66aa18 !important;
}

.bg-navy {
  background-color: #001f3f !important;
}

.bg-teal {
  background-color: #39cccc !important;
}

.bg-olive {
  background-color: #3d9970 !important;
}

.bg-lime {
  background-color: #01ff70 !important;
}

.bg-orange {
  background-color: #ff851b !important;
}

.bg-fuchsia {
  background-color: #f012be !important;
}

.bg-purple {
  background-color: #605ca8 !important;
}

.bg-maroon {
  background-color: #d81b60 !important;
}

.bg-gray-active {
  color: #000;
  background-color: #b5bbc8 !important;
}

.bg-black-active {
  background-color: #000 !important;
}

.bg-red-active,
.modal-danger .modal-header,
.modal-danger .modal-footer {
  background-color: #d33724 !important;
}

.bg-yellow-active,
.modal-warning .modal-header,
.modal-warning .modal-footer {
  background-color: #db8b0b !important;
}

.bg-aqua-active,
.modal-info .modal-header,
.modal-info .modal-footer {
  background-color: #00a7d0 !important;
}

.bg-blue-active {
  background-color: #005384 !important;
}

.bg-light-blue-active,
.modal-primary .modal-header,
.modal-primary .modal-footer {
  background-color: #357ca5 !important;
}

.bg-green-active,
.modal-success .modal-header,
.modal-success .modal-footer {
  background-color: #008d4c !important;
}

.bg-navy-active {
  background-color: #001a35 !important;
}

.bg-teal-active {
  background-color: #30bbbb !important;
}

.bg-olive-active {
  background-color: #368763 !important;
}

.bg-lime-active {
  background-color: #00e765 !important;
}

.bg-orange-active {
  background-color: #ff7701 !important;
}

.bg-fuchsia-active {
  background-color: #db0ead !important;
}

.bg-purple-active {
  background-color: #555299 !important;
}

.bg-maroon-active {
  background-color: #ca195a !important;
}

[class^="bg-"].disabled {
  opacity: 0.65;
  filter: alpha(opacity=65);
}

.text-red {
  color: #dd4b39 !important;
}

.text-yellow {
  color: #f39c12 !important;
}

.text-aqua {
  color: #00c0ef !important;
}

.text-blue {
  color: #0073b7 !important;
}

.text-black {
  color: #111 !important;
}

.text-light-blue {
  color: #3c8dbc !important;
}

.text-green {
  color: #00a65a !important;
}

.text-gray {
  color: #d2d6de !important;
}

.text-navy {
  color: #001f3f !important;
}

.text-teal {
  color: #39cccc !important;
}

.text-olive {
  color: #3d9970 !important;
}

.text-lime {
  color: #01ff70 !important;
}

.text-orange {
  color: #ff851b !important;
}

.text-fuchsia {
  color: #f012be !important;
}

.text-purple {
  color: #605ca8 !important;
}

.text-maroon {
  color: #d81b60 !important;
}

.link-muted {
  color: #7a869d;
}

.link-muted:hover,
.link-muted:focus {
  color: #606c84;
}

.link-black {
  color: #666;
}

.link-black:hover,
.link-black:focus {
  color: #999;
}

.hide {
  display: none !important;
}

.no-border {
  border: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-shadow {
  box-shadow: none !important;
}

.no-focus:focus {
  box-shadow: none;
}

button.btn-info,
button.btn-info:focus {
  background-color: #727272;
  border-color: #525252;
  color: white;
  box-shadow: none;
}

button.btn-info:hover {
  background-color: #333;
  border-color: #525252;
  color: white;
}

button.btn-primary:focus,
button.btn-close:focus {
  box-shadow: none;
}

button.transparentBtn {
  background-color: transparent;
  border: none;
}

div.modal {
  zoom: var(--ratio);
  zoom: var(--ratioP);
  display: flex !important;
  align-items: center;
}

div.modal-dialog {
  width: 70%;
  max-width: 90%;
}

div.smallerModal {
  width: 50%;
  max-width: 70%;
}

div.modal-content {
  max-height: 100vh;
}

div.modal-header > .btn-close {
  position: absolute;
  right: 0;
  top: 0px;
  background: none;
  opacity: 1;
  transition: all 0.5s ease-in;
}

div.modal-header > .btn-close::before {
  position: absolute;
  content: "\f00d";
  font-family: "Font Awesome 5 Pro";
  color: #fff;
  font-size: 24px;
  top: 0px;
  opacity: 1;
  left: 8px;
  font-weight: bold;
}

div.modal-header > .btn-close:hover {
  transform: rotate(90deg);
}

div.modal-content {
  overflow-y: auto;
  overflow-x: hidden;
}

.list-unstyled,
.chart-legend,
.contacts-list,
.users-list,
.mailbox-attachments {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-group-unbordered > .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.flat {
  border-radius: 0 !important;
}

.text-bold,
.text-bold.table td,
.text-bold.table th {
  font-family: "Poppins-Bold";
}

.text-sm {
  font-size: 12px;
}

.jqstooltip {
  padding: 5px !important;
  width: auto !important;
  height: auto !important;
}

.bg-teal-gradient {
  background: #39cccc !important;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #39cccc),
    color-stop(1, #7adddd)
  ) !important;
  background: -ms-linear-gradient(bottom, #39cccc, #7adddd) !important;
  background: -moz-linear-gradient(
    center bottom,
    #39cccc 0,
    #7adddd 100%
  ) !important;
  background: -o-linear-gradient(#7adddd, #39cccc) !important;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#7adddd', endColorstr='#39cccc', GradientType=0) !important;
  color: #fff;
}

.bg-light-blue-gradient {
  background: #3c8dbc !important;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #3c8dbc),
    color-stop(1, #67a8ce)
  ) !important;
  background: -ms-linear-gradient(bottom, #3c8dbc, #67a8ce) !important;
  background: -moz-linear-gradient(
    center bottom,
    #3c8dbc 0,
    #67a8ce 100%
  ) !important;
  background: -o-linear-gradient(#67a8ce, #3c8dbc) !important;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#67a8ce', endColorstr='#3c8dbc', GradientType=0) !important;
  color: #fff;
}

.bg-blue-gradient {
  background: #0073b7 !important;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #0073b7),
    color-stop(1, #0089db)
  ) !important;
  background: -ms-linear-gradient(bottom, #0073b7, #0089db) !important;
  background: -moz-linear-gradient(
    center bottom,
    #0073b7 0,
    #0089db 100%
  ) !important;
  background: -o-linear-gradient(#0089db, #0073b7) !important;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#0089db', endColorstr='#0073b7', GradientType=0) !important;
  color: #fff;
}

.bg-aqua-gradient {
  background: #00c0ef !important;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #00c0ef),
    color-stop(1, #14d1ff)
  ) !important;
  background: -ms-linear-gradient(bottom, #00c0ef, #14d1ff) !important;
  background: -moz-linear-gradient(
    center bottom,
    #00c0ef 0,
    #14d1ff 100%
  ) !important;
  background: -o-linear-gradient(#14d1ff, #00c0ef) !important;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#14d1ff', endColorstr='#00c0ef', GradientType=0) !important;
  color: #fff;
}

.bg-yellow-gradient {
  background: #f39c12 !important;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #f39c12),
    color-stop(1, #f7bc60)
  ) !important;
  background: -ms-linear-gradient(bottom, #f39c12, #f7bc60) !important;
  background: -moz-linear-gradient(
    center bottom,
    #f39c12 0,
    #f7bc60 100%
  ) !important;
  background: -o-linear-gradient(#f7bc60, #f39c12) !important;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#f7bc60', endColorstr='#f39c12', GradientType=0) !important;
  color: #fff;
}

.bg-purple-gradient {
  background: #605ca8 !important;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #605ca8),
    color-stop(1, #9491c4)
  ) !important;
  background: -ms-linear-gradient(bottom, #605ca8, #9491c4) !important;
  background: -moz-linear-gradient(
    center bottom,
    #605ca8 0,
    #9491c4 100%
  ) !important;
  background: -o-linear-gradient(#9491c4, #605ca8) !important;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#9491c4', endColorstr='#605ca8', GradientType=0) !important;
  color: #fff;
}

.bg-green-gradient {
  background: #00a65a !important;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #00a65a),
    color-stop(1, #00ca6d)
  ) !important;
  background: -ms-linear-gradient(bottom, #00a65a, #00ca6d) !important;
  background: -moz-linear-gradient(
    center bottom,
    #00a65a 0,
    #00ca6d 100%
  ) !important;
  background: -o-linear-gradient(#00ca6d, #00a65a) !important;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00ca6d', endColorstr='#00a65a', GradientType=0) !important;
  color: #fff;
}

.bg-red-gradient {
  background: #dd4b39 !important;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #dd4b39),
    color-stop(1, #e47365)
  ) !important;
  background: -ms-linear-gradient(bottom, #dd4b39, #e47365) !important;
  background: -moz-linear-gradient(
    center bottom,
    #dd4b39 0,
    #e47365 100%
  ) !important;
  background: -o-linear-gradient(#e47365, #dd4b39) !important;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#e47365', endColorstr='#dd4b39', GradientType=0) !important;
  color: #fff;
}

.bg-black-gradient {
  background: #111 !important;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #111),
    color-stop(1, #2b2b2b)
  ) !important;
  background: -ms-linear-gradient(bottom, #111, #2b2b2b) !important;
  background: -moz-linear-gradient(
    center bottom,
    #111 0,
    #2b2b2b 100%
  ) !important;
  background: -o-linear-gradient(#2b2b2b, #111) !important;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#2b2b2b', endColorstr='#111111', GradientType=0) !important;
  color: #fff;
}

.bg-maroon-gradient {
  background: #d81b60 !important;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #d81b60),
    color-stop(1, #e73f7c)
  ) !important;
  background: -ms-linear-gradient(bottom, #d81b60, #e73f7c) !important;
  background: -moz-linear-gradient(
    center bottom,
    #d81b60 0,
    #e73f7c 100%
  ) !important;
  background: -o-linear-gradient(#e73f7c, #d81b60) !important;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#e73f7c', endColorstr='#d81b60', GradientType=0) !important;
  color: #fff;
}

.dropdown .form-group {
  margin-bottom: 5px;
}

.dropdown .dropdown-toggle {
  text-align: left;
  padding: 0.5rem 0.75rem;
  font-family: Poppins-Regular, sans-serif;
  line-height: 30px;
  font-size: 24px;
  background-color: #fff;
}

.dropdown .dropdown-toggle:hover {
  background-color: white;
}

.dropdown .dropdown-toggle::after {
  float: right;
  line-height: 100%;
  margin-top: 12px;
  margin-right: 5px;
  font-size: 18px;
  color: #666;
}

.mx5p .dropdownContent {
  margin: 0 5rem 15px 5rem;
}

.mx5p .dropdownContent .form-group {
  margin: 0;
}

.dropdown .searchArea {
  padding: 5px 5%;
}

.dropdown .dropdown-menu {
  min-width: none;
  width: 100%;
}

.dropdown .dropdownContent {
  border: 2px solid #efefef;
  box-shadow: -2px -1px 5px #eee;
  border-top: none;
}

.dropdownContent .form-control {
  margin: 5px 0;
}

.downloadLink {
  text-decoration: none;
  color: navy;
  font-family: Poppins-Medium, sans-serif;
}

.MuiTablePagination-actions {
  width: 100px;
}