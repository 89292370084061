.popup {
  position: absolute;
  width: 300px;
  border: 1px solid #ccc;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  top: 50%;
  left: 50%;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
