.payment-container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img {
  width: 400px;
  height: 400px;
}

.img-success {
  width: 500px;
  height: 400px;
}

.payment-container span {
  font-size: 18px;
  font-weight: 500;
}
