.food-con {
    display: flex;
    justify-content:space-between;
    margin-left: 20px;
    
}

.table-con{
    display: flex;
    align-items: flex-start;
    gap: 50px;
}


.food-time-btn-con{
    margin-right: 20px;
     display: flex;
     align-items: flex-start;
}

.food-time-btn-con button {
    border: none;
    padding: 6px 12px;
    display: block;
    background-color: #2D2D2D;
    color: #fff;
    border-radius: 6px;

}

.food-time-btn-con button:first-child {
    margin-right: 40px;
}

.time-input {
    border: none;
}

.time-input:focus {
    outline: none;
  }