   .floatClass{
    float: right;
    margin-right: 20px;
    /* min-width: 100px; */
    
   }


   #alignment{
    width: 300px;
    position: relative;
    right: 90px;
   }
   .leadstatus{
      float: right;
    margin-right: 30px;
    position: sticky;
    bottom: 400px;
    /* align-self: flex-start; */
    left: 340px;
    position: -webkit-sticky;
    
   }

   
input.input-error,
select.input-error
{
      
    background-color: #fce4e4;
    border: 0.1px solid #ff01416a;
    outline: none;
}

label.checkbox-error,
.gender-error{
    color:red
}

p.error-message{
    color: red;
    display: flex;
    justify-content: center;
}

   