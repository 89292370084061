.card-items {
  width: 100%;
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 30px;
  border-radius: 6px;
}

.row-1 {
  width: 90%;
}

/* .select-box-con {
   
  } */

.row-2 label {
  margin-right: 10px;
}

.row-3 input,
.row-3 select {
  border: 1px solid #d0d0d0;
  padding: 8px 10px;
  border-radius: 5px;
}

.row-1 input {
  font-size: 15px;
  /* width: 00px; */
  padding: 9px 10px;
  border-radius: 5px;
}

.row-3 input {
  width: 100px;
}

/* .row-3 input {
     !important;
    padding: 5px 10px;
    border-radius: 5px;
  } */

.duration {
  width: 30%;
}

.duration label {
  display: block;
}

.duration input {
  width: 130px;
  border: 1px solid #d0d0d0 !important;
  padding: 9px 16px;
}

.MuiStack-root {
  padding: 0 !important;
  font-size: 15px !important;
}

.card-items label {
  margin-bottom: 5px;
  font-weight: 600;
  user-select: none;
}

.radio-con {
  display: flex;
  align-items: flex-end;
  gap: 2px;
}

.radio-con input {
  width: 25px;
}

.select-box-con label {
  display: block;
}

.select-box-con input,
.select-box-con select {
  width: 100%;
  border: 1px solid #d0d0d0;
  padding: 8px 10px;
  border-radius: 5px;
}

.workout-btn-con {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-right: 105px;
}

.workout-btn-con button:first-child {
  font-size: 16px;
  padding: 8px 30px;
  color: #fff;
  border: none;
  background: #2d2d2d;
  border-radius: 6px;
}

.workout-btn-con button:last-child {
  font-size: 16px;
  padding: 6.5px 20px;
  /* color: #fff; */
  border: 2px solid #2d2d2d;
  background-color: transparent;
  color: #2d2d2d;
  border-radius: 6px;
}

/* custom checkbox */

.radio {
  margin-bottom: 0 !important;
  display: flex;
  justify-content: center !important;
}

.repeat[type="checkbox"] {
  position: relative;
  border: 2px solid #000;
  border-radius: 3px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 2px 0.6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 18px;
  width: 18px;
  -webkit-appearance: none;
  opacity: 0.5;
}

.repeat[type="checkbox"]:hover {
  opacity: 1;
}

.repeat[type="checkbox"]:checked {
  background-color: #000;
  opacity: 1;
}

.repeat[type="checkbox"]:before {
  content: "";
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.week-btn {
  /* display: flex;
  gap: 10px;
  justify-content: center; */
  display: flex;
  gap: 10px;
}

.week-btn button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
}

.custom-row {
  width: 200px;
}

.custom-label {
}

.custom-input {
  -moz-appearance: textfield; /* Firefox */
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  width: 60px;
  padding: 8px 13px;
}

.custom-input::-webkit-outer-spin-button,
.custom-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-select {
  width: 100%;
  border: 1px solid #d0d0d0;
  padding: 8px 10px;
  border-radius: 5px;
}

.active-day {
  background-color: #2d2d2d;
  color: #fff;
}

.live-input label {
  display: block;
}

.live-input input {
  width: 100%;
}

.custom-add-schedule {
  display: flex;
  align-items: flex-end;
}

.custom-add-schedule button {
  font-size: 16px;
  padding: 8px 30px;
  color: #fff;
  border: none;
  background: #2d2d2d;
  border-radius: 6px;
}

.custom-select-input label {
  display: block;
}

.custom-select-input select {
  width: 50%;
  border: 1px solid #d0d0d0;
  padding: 8px 10px;
  border-radius: 5px;
}

.custom-icon,
.custom-clear {
  display: flex;
  align-self: flex-end;
  /* background-color: red; */
}

.custom-icon button {
  font-size: 16px;
  padding: 8px 10px;
  color: #fff;
  border: none;
  background: #2d2d2d;
  border-radius: 6px;
}

.custom-icon-rest {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-self: center;
}

.custom-icon-rest button {
  font-size: 16px;
  padding: 7px 7px;
  color: #fff;
  border: none;
  background: #2d2d2d;
  border-radius: 5px;
  margin-right: 10px !important;
}

.rest-input input {
  border: 1px solid #d0d0d0;
  padding: 8px 10px;
  border-radius: 5px;
  width: 112px;
  margin-left: 15px;
}

.rest-label {
  padding: 8px 20px;
  border-radius: 6px;
  background-color: transparent;
  border: 1px solid lightgray;
  margin-left: 5px;
}

.custom-icon.delete button {
  background-color: #df2e38;
}

.custom-clear button {
  font-size: 16px;
  padding: 8px 16px;
  color: #fff;
  border: none;
  background: #2d2d2d;
  border-radius: 6px;
}

.custom-close {
  display: flex;
  justify-content: flex-end;
}

.custom-close span {
  cursor: pointer;
}

.custom-checkbox-con {
  width: 60px !important;
  display: flex;
  align-items: center;
}
#checkbox-wp {
  width: fit-content !important;
  display: flex;
  align-items: center;
}
#wpExerciseName {
  width: fit-content !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#checkbox-wp .repeat {
  margin: 0;
  margin-top: 5px;
}
#wp-sub-level label{
margin-bottom: 5px !important;
}
/* #subLevel1{
  background-color: #fff !important;
} */

#checkbox-wp-rest .repeat {
  margin: 0;
  margin-top: 10px;
}
.custom-rep input {
  width: 100%;
}

.form-custom {
  width: 100%;
}

/* .vmax-duration {
  width: 50%;
  border: 1px solid #d0d0d0 !important;
  padding: 8px 10px;
  border-radius: 5px;
} */

/* .live-duration {
  display: flex;
  align-items: flex-end;
} */

.live-duration label {
  display: block;
}

.live-duration input {
  width: 100%;
}

.rest-btn {
  display: flex;
  align-items: flex-end;
}

.rest-btn button {
  font-size: 16px;
  padding: 8px 30px;
  color: #fff;
  border: none;
  background: #2d2d2d;
  border-radius: 6px;
}

.custom-rest {
  width: 60%;
}
