.history-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding-bottom: 20px;
}

.history-container h3 {
  padding: 20px;
}

.history-header {
  width: 600px;
  margin: 20px;
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
}

.history-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.history-title-container h5 {
  margin: 0;
  padding: 0;
}

.history-item {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.item-title {
  font-size: 18px;
  font-weight: 600;
}

.item-title-two {
  font-size: 16px;
  font-weight: 600;
}

.faq-accordion-container {
  width: 400px;
  margin: 50px auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.faq-description {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
}

.faq-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-item.open {
  background-color: #fff;
  border-color: #007bff;
}

.faq-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.expand-icon {
  transition: transform 0.3s ease;
}

.expand-icon.open {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 12px;
  display: none;
}

.faq-item.open .faq-answer {
  display: block;
}
