.form-session {
  width: 90%;
  margin-top: 50px;
  padding-left: 150px;
  font-size: 18px;
}

/*  Live session component */
.live-session {
  width: 100%;
  margin-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.live-session-container {
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
}

/*  Live Session Dashboard component */

.live-session-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.live-session-header h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

/* Live Session Tabs component */

.tab-container {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.session-tab button {
  border: none;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.589);
  padding-bottom: 6px;
  border-bottom: 2px solid transparent;
  font-size: 18px;
  font-weight: 600;
}

.session-tab button.active,
.session-tab button:hover {
  color: black;
  border-bottom: 2px solid black;
}

/*  edit occurrence */

.session-occruence {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 1rem;
}

/* view Booking  */

.view-booking {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 830px;
  height: 600px;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 1rem;
  padding-bottom: 4rem;
}

.view-Header {
  display: flex;
  gap: 20px;
}

.view-Header h4 {
  font-size: 22px;
}

/* create Session component */

.session-backdrop {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.379);
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100;
}

.session-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1200px;
  height: 636px;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 1rem;
  overflow-y: scroll;
}

.session-subHeader {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.session-row {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 10px;
}

.session-col {
  width: 100%;
}

.session-col label {
  margin-bottom: 10px;
}

.choose-img-container {
  display: flex;
  margin-bottom: 50px;
}

.choose-img-input {
  width: 330px;
}

.choose-img-icon {
  width: 0px;
}

.choose-image {
  width: 100px;
  height: 100px;
  margin-left: 37px;
}

.session-col .choose-img:focus {
  outline: none;
  box-shadow: none;
  border: 2px solid #2d2d2d;
}

.session-mode {
  display: flex;
  gap: 20px;
}

.session-btn {
  display: flex;
  gap: 10px;
  padding: 8px 16px;
}

.session-btn.repeat {
  border: none;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: #2d2d2d;
}

.format-input input {
  padding: 9px 16px;
  border-color: red !important;
}

.session-btn-container {
  display: flex;
  justify-content: center;
}

.session-btn-confirm {
  padding: 8px 35px;
  margin-right: 20px;
}

.custom-col .MuiTextField-root {
  width: 100%;
}

.repeat-contianer {
  margin-bottom: 10px;
}

.session-week-btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
  flex-grow: 1;
}

.session-week-btn button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
}

.active-day {
  background-color: #2d2d2d;
  color: #fff;
}

.empty {
  width: 0px;
}

/*  Sub class */

.sub-margin {
  margin-bottom: 20px;
}

.sub-margin-high {
  margin-bottom: 40px;
}

/* Zoom heading */

.zoom-container {
  display: flex;
  justify-content: space-between;
}

.zoom {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}

.zoom label {
  display: flex;
  justify-content: center;
  margin-right: 20px;
}

.require-con {
  width: 200px;
  display: flex;
}

.require-con label {
  margin: 1px;
  margin-bottom: 10px;
}

.require-con span {
  color: red;
  font-size: 18px;
}
.require-con.host label {
  margin-bottom: 0;
}

.back-session {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
