/* create template workout plan */

.workout-plan-con.template {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 70px;
}

.temlate-container {
  margin-top: 20px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.template-title-con {
  display: flex;
  align-items: center;
  gap: 50px;
}

.temlate-plan-col {
  width: 200px;
}

.temlate-plan-col.date-picker-con input {
  padding: 8px 16px;
}

.temlate-plan-col label {
  font-weight: 500;
  margin-bottom: 10px;
}

.temlate-plan-col input {
  display: block;
  width: 200px;
  padding: 10px 20px;
  border-radius: 6px;
}
.date-picker-costum input {
  padding: 10px 0px 10px 14px !important;
}
/* .date-picker-costum label {
  padding: 0px 40px;
  padding-top: 0px !important;
  padding-bottom: 20px !important;
  margin-bottom: 10px;

} */

.template-btn {
  font-size: 18px;
  padding: 8px 16px;
  color: #fff;
  border: none;
  background: #2d2d2d;
  border-radius: 6px;
  margin-top: 30px;
}

/* create workout plan */

.workout-plan-con {
  width: 100%;
  margin-bottom: 80px;
}
.workout-plan-row {
  /* width: 50%; */
  display: flex;
  gap: 20px;
  padding: 15px;
  margin-bottom: 32px;
}

.workout-plan-row {
  /* width: 50%; */
  display: flex;
  gap: 20px;
  padding: 15px;
  margin-bottom: 32px;
}

.workout-plan-col {
  width: 200px;
  gap: 1px;
}

.workout-plan-col input
{
  width: 180px;
  /* border: 1px solid #d0d0d0; */
  padding: 10px 8px;
  border-radius: 6px;
}
.workout-plan-col select {
  width: 180px;
  border: 1px solid #d0d0d0;
  padding: 10px 8px;
  border-radius: 6px;
}
#programName {
  border: 1px solid #d0d0d0;
}
.custom-input {
  -moz-appearance: textfield; /* Firefox */
  border: 1px solid #d0d0d0 !important;
  border-radius: 5px;
  width: 60px;
  padding: 8px 13px;
}

.workout-plan-col label {
  margin-bottom: 6px;
  font-weight: 600;
}
.date-picker-con label {
  font-weight: 300 !important;
}

.workout-plan-col:last-child input {
  border: none;
}

.workout-plan-row:last-child {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.workout-second-box {
  display: flex;
  gap: 20px;
}

.btn-con {
  display: flex;
  gap: 20px;
}

.btn-con-button {
  font-size: 18px;
  padding: 9px 18px;
  color: #fff;
  border: none;
  background: #2d2d2d;
  border-radius: 6px;
}

.input {
  border: 1px solid #d0d0d0 !important;
}

.snackbar-container {
  top: -70%;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
  font-weight: bold;
  position: fixed;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-item {
  flex: 0 0 calc(22.22% - 20px);
  padding: 10px;
  text-align: left;
  box-sizing: border-box;
}

.flex-item p:first-child {
  font-weight: 600;
}

/* .border-shadow {
  border: 1px;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px gray;
} */

.border-shadow {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
}
#border-shadow {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
}