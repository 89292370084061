.report-card {
  width: 100%;
  margin-top: 10px;
  padding-left: 50px;
  padding-right: 85px;
}

.report-table-container {
  width: 100%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 20px;
}

.report-header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 30px 0;
}

.report-header h3 {
  padding: 0;
  margin: 0;
  font-size: 25px;
  font-weight: 500;
}

.report-date-container {
  width: 200px;
}

.report-date-container input {
  padding: 9px 10px;
  border-radius: 5px;
}

/* Picture modal 
*/

.progress_picture {
  position: absolute;
  width: 720px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 1000;
  padding: 1.5rem;
}

.progress_picture h3 {
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 25px;
}

.meal-img-con {
  display: flex;
  gap: 15px;
}

.no_data {
  display: block;
  text-align: center;
  font-weight: 500;
}

/* comment */

.report-comment-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-edit-icon {
}

.report-title {
  display: flex;
  gap: 15px;
  align-items: center;
}

.report-sub-title {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 5px;
  color: #6b6b6b;
}

.report-title-edit {
  font-size: 12px;
  margin-left: 0px;
  margin-top: 2px;
  color: #6b6b6b;
}

.comment__header,
.report__modal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/*  live-session modal */

.live_session_modal {
  width: 500px;
  padding: 15px;
}

.live_session_modal h1 {
  font-size: 20px;
  margin-bottom: 25px;
  text-align: center;
}

.live_session_items:not(:last-child) {
  margin-bottom: 50px;
}

.live_session_items div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.live_session_items div span:first-child {
  font-weight: 500;
}
