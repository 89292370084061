.extension-backdrop {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.379);
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100;
}

.extension-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 1rem;
}

.extension-row {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 10px;
}

.extension-col {
  width: 100%;
}

.extension-col label {
  margin-bottom: 10px;
  font-weight: 500;
}

.extension-sub-margin {
  margin-bottom: 30px;
}
