@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
  font-family: "Poppins", sans-serif !important;
  font-size: 20px;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

p {
  margin-bottom: 0.7rem;
}

ul li {
  margin-bottom: 6px;
}

strong {
  font-weight: 600;
  font-size: 18px;
}

.bg__grey {
  color: black;
  background-color: rgb(200, 194, 188) !important;
}

.bg__transparent {
  background-color: transparent !important;
}

.table__head {
  color: white;
  background-color: rgb(32, 38, 46);
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  padding: 5px 0;
}

table {
  width: 100%;
}

#tableCss tr td{
  border: 3px solid black;
  padding: 10px;
  font-size: 18px;
  color: black;
 border-width: 1px;
}

.table__w33 tr td:nth-child(2),
.table__w33 tr td:nth-child(3) {
  width: 25%;
}

ul {
  list-style: none;
}

/* ul li:before {
  content: "✓";
} */

* {
  box-sizing: border-box;
}

.column {
  float: left;
  width: 50%;
  height: 50%;
  padding: 15px;
}

/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}

.imageborder {
  padding: 10px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  height: 20%;
}
