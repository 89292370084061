.leftSidealignment{
  position: sticky;
  left: 300px;
  font-size: 18px;
  /* right: 200px; */
  z-index: 1;
}

.fontSize{
  font-size: 180px;
}

.reduce_length{

  position: sticky;
  left: 300px;
  font-size: 18px;
  /* right: 200px; */
  inline-size: 450px;

  word-wrap: break-word;
  z-index: 1;
  
}

#textFormat{
  font-size: 18px;
}
.rightSidealignment{
  position: sticky;
  left: 1200px;
  /* flex: 1; */
  
  font-size: 18px;
  /* right: 200px; */
  z-index: 2;
}