.container {
  margin: 15px;
}

.header-txt {
  font-weight: 600 !important;
  font-size: 22px !important;
  margin-right: 34% !important ;
}

.sub-header {
  font-weight: 500 !important;
  font-size: 22px !important;
}
