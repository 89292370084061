.btn-common {
  /* width: 100%; */
  font-size: 16px;
  padding: 8px 30px;
  border-radius: 6px;
  font-weight: 600;
  border: 1px solid #2d2d2d;
  cursor: pointer;
}

.btn-outline {
  background-color: transparent;
  color: #2d2d2d;
}

.btn-inline {
  background-color: #2d2d2d;
  color: #fff;
}

.btn-disabled {
  opacity: 80%;
}
